import React from "react"

import { HeroDivWithSubtitle, HeroDivWithEnumeration, HeroDivWithDescription, SectionAccordionList, SectionWithMultipleContents, EmptySection, HeroDivWithLink, HeroDiv, Lines, HighlightedLines, TabbedLines } from "./sections";
import { CustomContactDiv } from "./page";
import SEO from "../seo";

import APIHelper from "../../utils/helpers/api"
import { EmptyComponent } from "./base";

import LayoutV2 from "../../components/v2/layout";

import "../../utils/css/v2.css";

export class PageContentBuilder extends React.Component {
    constructor(props){
        super(props);
        this.state = {
            loaded : false,
            data: {}
        };

        console.log("he;;p")
    }

    async componentDidMount() {
        const {api = true, subsection = "", PageContent = {}} = this.props;

        if(api){
            APIHelper.Get(`/contents/id/${subsection}`, (err, response) => {
                if (err) {
                    console.log(`Something bad happened while fetching the data\n${err}. Loading build content.`);
                    this.setState({ isError: true,  loaded: true, data: PageContent})
                } else {
                    this.setState({ loaded: true, data: response.data})
                }
            })
        }
        else{
            this.setState({ loaded: true, data: PageContent})
        }
    }

    render () {
        const { 
            headerActive = "", 
            location = "", 
            subsection = "",
            useComponent = false,
            component = <EmptyComponent/>
        } = this.props, 
        { 
            loaded = false, 
            data: { contents = [], meta = [], title = "" }
        } = this.state, 
        pageclass = subsection.replace("partial_", "");

        return <LayoutV2 location={location} headerActive={headerActive} showFooter={loaded}>
            <SEO title={title} meta={meta} />
            { useComponent ?
                <>{component}</> :  
                loaded ? <article className={`psg-section psg-section-${pageclass} post-content page-template no-image scroller-adjustment animated fadeIn`}>
                    <div className={"post-content-body"}>
                        {
                            contents.map((item) =>{
                                console.log(item);
                                const {type, content, section, className, contents, tabs = 0 } = item;
    
                                if(type === "hero_div_with_subtitle"){
                                    return <HeroDivWithSubtitle content={content} className={className}/>
                                }
                                else if(type === "hero_with_enumeration"){
                                    return <HeroDivWithEnumeration content={content} section={section}/>
                                }
                                else if(type === "hero_with_description"){
                                    return <HeroDivWithDescription content={content} section={section}/>
                                }
                                else if(type === "section_accordion_list"){
                                    return <SectionAccordionList content={content} section={section}/>
                                }
                                else if(type === "contact_div"){
                                    return <CustomContactDiv content={content} section={section} sectionLabelRight={`04`} onClickFadeOut={true} ignoreScrollTop={true}/>
                                }
                                else if(type === "section_with_multiple_contents"){
                                    return <SectionWithMultipleContents content={content} section={section}/>
                                }
                                else if(type === "section_div"){
                                    return <EmptySection section={section} contents={contents}/>
                                }
                                else if(type === "hero_div_with_link"){
                                    return <HeroDivWithLink content={content}/>
                                }
                                else if(type === "hero_div") {
                                    return <HeroDiv content={content}/>
                                }
                                else if(type === "lines") {
                                    return <Lines contents={contents}/>
                                }
                                else if(type === "highlighted_lines") {
                                    return <HighlightedLines contents={contents}/>
                                }
                                else if(type === "tabbed_lines") {
                                    return <TabbedLines contents={contents} tabs={tabs}/>
                                }
                                else{
                                    return <EmptyComponent/>
                                }
                            })
                        }
                    </div>
                </article> :  <EmptyComponent/>
            }
        </LayoutV2> 
    }
}